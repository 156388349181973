import React from 'react';
import BlockchainConnectPageTemplate from '../../../components/Connect/Blockchain';

const subtitles = {
  A: (
    <>
      With the successful delivery of a number of Blockchain projects, Tintash has developed a range
      of expertise in Blockchain development and leverages the same product development proficiency
      that helped our startups, unicorns and fortune 500 clients to realize their dreams.
    </>
  ),
  B:
    'Leverage our expert blockchain developers to elevate your project, delivering the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <BlockchainConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Industry Leading Blockchain Development Company"
      subtitles={subtitles}
      metaTitle="Industry Leading Blockchain Development Company | Tintash"
      metaDescription="Tintash is an industry leading Blockchain development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Blockchain/Blockchain.Development"
    />
  );
}

export default index;
